export const WORDS = [
  'abort',
  'adobe',
  'algos',
  'alias',
  'apple',
  'arial',
  'array',
  'ascii',
  'asset',
  'audio',
  'batch',
  'blade',
  'block',
  'board',
  'bools',
  'boost',
  'break',
  'build',
  'built',
  'bytec',
  'bytes',
  'cache',
  'calls',
  'catch',
  'cells',
  'chart',
  'chats',
  'class',
  'clear',
  'click',
  'clone',
  'close',
  'cloud',
  'codec',
  'color',
  'comma',
  'const',
  'cores',
  'count',
  'crash',
  'daily',
  'debug',
  'delay',
  'digit',
  'drive',
  'elixr',
  'email',
  'enter',
  'enume',
  'enums',
  'equal',
  'erase',
  'error',
  'errors',
  'evals',
  'event',
  'excel',
  'fails',
  'false',
  'fiber',
  'field',
  'files',
  'finds',
  'first',
  'flash',
  'flask',
  'float',
  'focus',
  'folds',
  'forth',
  'frame',
  'funcs',
  'gigas',
  'glade',
  'gnome',
  'goran',
  'gotos',
  'graph',
  'group',
  'gsoap',
  'hashs',
  'haskl',
  'hertz',
  'hooks',
  'image',
  'index',
  'infix',
  'input',
  'intel',
  'issue',
  'jsons',
  'kiosk',
  'known',
  'label',
  'large',
  'latch',
  'layer',
  'lazer',
  'learn',
  'level',
  'links',
  'linux',
  'lists',
  'local',
  'locks',
  'logic',
  'login',
  'logon',
  'logos',
  'loops',
  'lower',
  'ltree',
  'macro',
  'maria',
  'match',
  'media',
  'micro',
  'modal',
  'model',
  'modem',
  'mouse',
  'multi',
  'mysql',
  'named',
  'names',
  'nexus',
  'night',
  'nodes',
  'noise',
  'nokia',
  'oauth',
  'opera',
  'optic',
  'order',
  'pager',
  'pages',
  'paint',
  'panel',
  'parse',
  'paste',
  'patch',
  'pixel',
  'plans',
  'point',
  'ports',
  'proxy',
  'purge',
  'query',
  'queue',
  'radio',
  'range',
  'react',
  'reads',
  'ready',
  'regex',
  'relay',
  'reset',
  'reuse',
  'robot',
  'rsync',
  'rules',
  'scale',
  'scope',
  'script',
  'scrum',
  'setup',
  'shell',
  'shift',
  'short',
  'shows',
  'skype',
  'slack',
  'slays',
  'smart',
  'solid',
  'sorts',
  'space',
  'stack',
  'state',
  'stats',
  'steps',
  'store',
  'story',
  'strip',
  'style',
  'suite',
  'swift',
  'table',
  'tasks',
  'tesla',
  'tests',
  'think',
  'threw',
  'ticks',
  'times',
  'tools',
  'trace',
  'track',
  'trees',
  'truth',
  'twice',
  'types',
  'union',
  'units',
  'unity',
  'unzip',
  'upper',
  'using',
  'valid',
  'value',
  'virus',
  'voids',
  'wedge',
  'while',
  'wires',
  'works',
  'write',
  'wrote',
  'xpath',
  'yahoo',
  'yield',
]
